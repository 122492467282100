.list-header, .list-item, .line-item {
    display: flex;
    justify-content: space-around;
    //align-items: center;
    //flex-wrap: wrap;
    //align-content: center;
 }
 
 .list-header {
    margin: 1rem 0;
    padding: 1rem 0;
    background-color: $chathams-blue;
    
    &.indented {
       margin: 1rem;
       padding: 1rem;
    }
 
    &.with-link {
       [class^='col-'] {
          //text-decoration: underline;
          cursor: pointer;
          color: $white;
          transition-duration: 0.3s;
          &:hover {
             color: $white;
          }
          &:active {
             color: $white;
          }
       }
    }
 }
 
 .list-item {
    border: 1px solid $lighter-gray;
 
    margin: 0.5rem 1rem;
    padding: 1rem 0;
    
    &:first-child {
       margin-top: 0;
    }
 
    &:hover {
       font-weight: bold;
       text-decoration: none;
    }
 
    [class^='col-'] {
       color: $chathams-blue;
    }
 
    &.border {
       border: 2px solid $lighter-gray;
       &:hover, &:active {
          border-color: $flamenco;
       }
    }
 }

 .link-button {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: $flamenco;
    }
 }
 

 