/*! Developed by by Dilantha Perera */

@import "bootstrap/bootstrap.scss";
@import "variables";
@import "breakpoints";
@import "mixins";
@import "animations";
@import "cng3d-icons";
@import "cng3d-gallery-core.scss";
@import "cng3d-landing-core.scss";
@import "cng3d-portal-core.scss";

// Continuity Imports
@import "./elements/_datepicker.css";
@import "./elements/loading-overlay";
@import "./elements/nav-dropdown";
@import "./elements/dialog";
@import "./elements/portal-rendering-engine";
@import "./elements/dropzone.scss";
@import "./elements/account-list";

.cng3d-body {
  font-family: $font-stack-secondary;
  background: $primary-bg url(../images/general-bg-01.jpg);

  * {
    outline: none !important;
    touch-action: manipulation; // disable double tap zoom on IOs
  }
}

a {
  color: $primary-color;

  &:hover {
    color: $primary-color-light;
  }
}

.radius-sm {
  border-radius: 5px;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}
.data {
  font-size: 1.5rem !important;
}

/* --- borders ------------------------------------------------------------------------------- */
.b-color-base {
  border-color: $base-color !important;
}

.b-color-primary {
  border-color: $primary-color !important;
}

.b-color-secondary {
  border-color: $light-orange !important;
}

/* --- backgrounds ------------------------------------------------------------------------------- */
.bg-color-none {
  background-color: transparent !important;
}

.bg-primary-color {
  background-color: $primary-color;

  &.glass {
    background: ($primary-color) + b5;
  }
}

.bg-black {
  background-color: $pitch-black;

  &.glass {
    background: ($pitch-black) + b5;
  }
}

.bg-white {
  background-color: $primary-white;

  &.glass {
    background: ($primary-white) + bf;
  }
}

.bg-powder-blue {
  background-color: $powder-blue;

  &.glass {
    background: ($powder-blue) + bf;
  }
}
.bg-primary-color-light {
  background-color: $primary-color-light;
}

.bg-base-color {
  background-color: $base-color;

  &.glass {
    background: ($base-color) + b5;
  }
}

.bg-rendering-engine {
  background-color: #c5dbe2;
}

/* --- Text ------------------------------------------------------------------------------- */

.slider-primary-color {
  color: $elephant;
}

.txt-white {
  color: $primary-white;
}

.txt-black {
  color: $pitch-black;
}

.txt-primary-color {
  color: $primary-color;
}

.txt-primary-color-light {
  color: $primary-color-light;
}

.text-light-blue {
  color: $aqua-island;
}

.text-bright-blue {
  color: $holiday-blue;
}

.create-blue {
  color: $pelorous;
}

.upload-blue {
  color: $orange-peel;
}

.text-orange {
  color: $bright-orange;
}

.darker-green {
  color: $darker-green;
}

.darker-red {
  color: $mahogani;
}

.text-dark-blue {
  color: $chathams-blue;
}

/* --- Backgrounds ------------------------------------------------------------------------------- */
header {
  background: $base-color;
}

.bg-lighter-gray {
  background-color: $lighter-gray;
}

.bg-yellow {
  background: $golden-yellow;
}

.bg-orange {
  background: $bright-orange;
}

.bg-red {
  background: $theme-red;
}

/* --- Nav ------------------------------------------------------------------------------- */
.navbar {
  margin-bottom: 0;

  .nav-item {
    h2 {
      @media screen and (max-width: map-get($breakpoints, "small")) {
        font-size: 1rem;
      }
    }
  }
}
.cng3d-navbar {
  transition: all 600ms ease;

  &--scrolled {
    background: #053303e6;
    box-shadow: 1px 1px 25px #545454;
    border-bottom: 1px solid #072f06;
  }
  .navbar-collapse {
    &.show .navbar-nav {
      position: fixed;
      height: 100vh;
      top: 0;
      right: 0;
      padding: 70px 40px;
      background: #f8fafbf0;
      box-shadow: 1px -4px 5px #525252;
      z-index: -2;

      @include respond-to(medium) {
        align-items: flex-end;
        position: relative;
        height: auto;
        box-shadow: none;
        padding: 0;
        display: flex;
        flex-direction: row;
      }
      .nav-item {
        line-height: 70px;
        border-bottom: 1px dashed #9aa8b8;
        font-size: 14px;

        @include respond-to(medium) {
          border: none;
          font-size: calc(0.2vw + 0.2vh + 0.9vmin);
          padding: 0 1vw;
        }

        @include respond-to(large) {
          font-size: calc(0.2vw + 0.2vh + 1.2vmin);
          padding: 0 1.3vw;
        }
      }
    }
  }

  .navbar-brand {
    @include respond-to(medium) {
      height: 75px;
    }

    img {
      height: 50px;

      @include respond-to(medium) {
        height: 100%;
      }
    }
  }

  .navbar-nav {
    font-size: 20px;

    @include respond-to(medium) {
      font-size: calc(0.95vw + 0.15vh);
    }
  }

  .nav-link {
    @include mat-transition;

    &:hover {
      color: $flamenco;
      cursor: pointer;
    }
  }
}

.impersonation-stop {
  &:hover {
    color: $bright-orange;
    cursor: pointer;
  }
}

.cng3d-navbar-top {
  padding: 0 !important;

  &.cng3d-navbar-scrolled {
    background: #c3dceaf5;
    box-shadow: 1px 1px 4px #83a0b1;
  }

  @include respond-to(medium) {
    padding: 20px;
  }

  .navbar-nav {
    flex-direction: row;
    width: 100%;

    @include respond-to(small) {
      justify-content: flex-end;
      width: auto;
    }

    .nav-item {
      flex: 33;

      @include respond-to(medium) {
        flex: initial;
      }

      .nav-link {
        height: auto;
        line-height: 60px;
        white-space: nowrap;
        padding: 0 30px;

        @include respond-to(medium) {
          line-height: 40px;
        }

        &.learning {
          background: #3d9dc9;
          border: 1px solid #2a9ace;
          color: #fff;

          @include respond-to(small) {
            background: transparent;
            border: 0;
            color: #134179;
          }
        }

        &.library {
          background: #134179;
          border: 1px solid #3a76d1;
          color: #fff;

          @include respond-to(small) {
            background: transparent;
            border: 0;
            color: #134179;
          }
        }

        &.get-quote {
          background: #ff8800;
          border: 1px solid #ff7300;
          color: #fff;
        }

        &.btn-home {
          background: $celtic;
          border: 1px solid $banana-green;
          color: #fff;
        }
      }
    }
  }
}

/* --- Buttons ------------------------------------------------------------------------------- */

.cng3d-general-button {
  font-size: 1.5rem !important;
  margin: 0px 2px;

  &.highlighted {
    background: #ff8800;
    border: 1px solid #ff7300;
    color: #fff !important;
  }

  &.default {
    background: #24627b;
    border: 1px solid #1b5369;
    color: #fff;
  }
  &.default-text {
    color: #24627b;
  }

  &.transparent {
    background: transparent;
    border: none;
    color: #24627b;
  }
  &.caution {
    background: #b40000;
    border: none;
    color: #fff;
  }
}

.cng3d-home-button {
  &.highlighted {
    background: #24627b;
    border: 1px solid #1b5369;
    color: #fff !important;
  }

  &.default {
    background: #24627b;
    border: 1px solid #1b5369;
    color: #fff;
  }

  &.transparent {
    background: transparent;
    border: none;
    color: #24627b;
  }
}

.cardBadge {
  position: absolute;
  right: 0rem;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;

  padding: 0.3rem 0.6rem;

  width: fit-content;

  font-size: 1.5rem;

  font-weight: 600;
  color: #fff;
}

.card {
  @include respond-to(medium) {
    min-height: 150px;
  }

  &[data-isNew="true"] {
    &::before {
      @extend .cardBadge;

      content: "New";
      background-color: $bright-orange;
    }
  }

  &[data-isTrending="true"] {
    &::after {
      @extend .cardBadge;

      content: "Trending";
      top: 3.25rem;
      background-color: $primary-color;
    }
  }
}
