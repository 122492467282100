/*! Developed by by Dilantha Perera */

/* --- Slider ------------------------------------------------------------------------------- */
.featured-slide {
    display: flex;
    flex-direction: column-reverse;
    height: 92vh;
    position: relative;

    @include respond-to(medium) {
        height: 84vh;
    }

    @include respond-to(medium) {
        flex-direction: row;
    }

    .cng3d-slider-content-wrap {
        h1 {
            font-size: 40px;
            font-weight: 800;
            letter-spacing: -4px;
            text-align: center;

            @include respond-to(medium) {

                font-size: 4.5vw;
                text-align: left;
            }
            @include respond-to(large) {

                font-size: 68px;

            }
        }

        h2 {
            font-size: 20px;
            text-align: center;

            @include respond-to(medium) {


                text-align: left;
                font-size: 1.5vw;
            }
        }

    }

    .slider-wrapper {
        width: 100%;
        background: url(../images/neuralnetworking.png) 45% 75% no-repeat;
        background-size: 90%;


        

        @include respond-to(small) {

            transform: translateY(70px);
        }
        @include respond-to(medium) {

            height: 90%;
            width: 65%;
        }

        .cng3d-moto-img {
            width: 400px;
            margin: 10px auto;

            @include respond-to(medium) {

                margin: 10px;
            }

        }

        .more-info-linker {
            position: relative;

            .linker {
                width: 455px;
                transform: scale(0.5) translate(-228px, -29px);
                display: inline-block;
            }

            .linker-mobile {
                width: 75%;
                margin: 0 auto;
                position: absolute;
                left: 12.5%;
            }

            .link-wrapper {

                text-align: center;
                height: 100px;
                padding-top: 25px;
                z-index: 100;
                position: relative;
                font-size: 1.5rem;

                @include respond-to(small) {
                    position: absolute;
                    top: 49px;
                    left: 200px;
                    padding-top: 0;
                }

                @include respond-to(medium) {
                    position: absolute;
                    top: 49px;
                    left: 200px;
                    padding-top: 0;
                }

                .single-link {
                    float: none;
                    display: inline-block;
                    min-width: 65px;
                    text-align: center;

                    @include respond-to(medium) {
                        float: left;
                    }

                    a {

                        height: 41px;
                        border-radius: 50%;
                        padding: 10px 10px 10px 11px;
                        line-height: 15px;
                        display: block;
                        margin: 0 auto;


                        i {
                            font-size: 24px;
                            color: #fff;
                        }

                    }


                    &.link01 {

                        a {
                            width: 40px;
                            background: #134179;

                            i.fa-unity {
                                margin-left: -4px;
                            }

                        }

                    }

                    &.link02 {

                        a {
                            width: 40px;
                            background: #ff8800;

                        }

                    }

                    &.link03 {

                        a {
                            width: 40px;
                            background: #3D9DC9;

                        }

                    }


                }


            }
        }

    }

}

/* --- Slider End ------------------------------------------------------------------------------- */



/* --- Detail page Start ------------------------------------------------------------------------------- */

.detail-contents {

    .detailed-area {



        @include respond-to(medium) {

        }
    }




    h1 {
        font-size: 4vh;
        font-weight: 900;

        @include respond-to(medium) {
            font-size: 6vw;
            letter-spacing: -5px;
        }

        @include respond-to(large) {
            font-size: 90px;
        }
    }

    h2 {
        font-size: 3vh;

        @include respond-to(medium) {
            font-size: 2.8vw;
        }
    }

    h3 {
        font-size: 2vh;

        @include respond-to(medium) {
            font-size: 1.8vw;
        }
    }

    p,
    li {
        font-size: 18px;

        @include respond-to(medium) {

            font-size: 1.5vw;

        }

        @include respond-to(large) {

            font-size: 20px;
        }
    }

}



/* --- Detail page END  ------------------------------------------------------------------------------- */


.enable-scroll-styling {
    scrollbar-color:#0f5584 #fff;
    scrollbar-width: thin;
  }
  
.enable-scroll-styling::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.enable-scroll-styling::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #F5F5F5;
}

.enable-scroll-styling::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    background-color: #0f5584;
}