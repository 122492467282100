@import '../bootstrap/breakpoints';

// Dropdown for navbar sub options
.dropdown-nav {
    float: left;
    overflow: hidden;
    padding-bottom: 2px;
}
.dropdown-nav-content {
    display: none;
    opacity: 0;
    position: absolute;
    padding: 2px 10px;
    text-align: center;
    background-color: $porcelain;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
.dropdown-nav-content li {
    list-style-type: none;
}
.dropdown-nav-content li a {
    clear: both;
    width: 100%;
    text-align: left;
}
.dropdown-nav:hover .dropdown-nav-content {
    @include respond-to(medium) {
        display: block;
        opacity: 1;
        }
}
.caret-down {
    @media screen and (max-width: map-get($breakpoints, 'medium')) {
        display: none;
    }
}