/* Developed by by Dilantha Perera */

/* ----- Colors ---------------------------------------------------------------------------- */
@import 'colors';
@import 'cng3d-fonts';

$base-color: $golden-yellow;
$primary-color: $chathams-blue;

$primary-white: $pure-white;
$primary-color-light: $matisse-blue;

$pitch-black: $black;
$bright-orange :$flamenco;

$light-orange : lighten($flamenco, 25);
$lighter-gray :$iron;
$darker-green :$celtic;


$primary-bg : $aqua-island;


/* ----- Fonts ---------------------------------------------------------------------------- */
.f500 {
    font-weight: 500;
}

.f600 {
    font-weight: 600;
}

.f800 {
    font-weight: 800;
}

.uppercase {
    text-transform: uppercase;
}

.fas,
.far,
.fal,
.fad,
.fab {
    font-size: $primary-font-size + 4;
}


.font-stack-sm2 {
    font-size: $primary-font-size - 2;
}

.font-stack-sm1 {
    font-size: $primary-font-size - 1;
}

.font-stack-s0 {
    font-size: $primary-font-size;
}

.font-stack-s1 {
    font-size: $primary-font-size + 1;
}

.font-stack-s2 {
    font-size: $primary-font-size + 2;
}
.font-stack-s3 {
    font-size: $primary-font-size + 3;
}
.font-stack-s4 {
    font-size: $primary-font-size + 4; //16px 
}
.font-stack-s5 {
    font-size: $primary-font-size + 5;
}
.font-stack-s6 {
    font-size: $primary-font-size + 6;
}
.font-stack-s9 {
    font-size: $primary-font-size + 9;
}
.font-stack-s10 {
    font-size: $primary-font-size + 10;
}
.font-stack-s12 {
    font-size: $primary-font-size + 12;
}
.font-stack-s14 {
    font-size: $primary-font-size + 14;
}

.font-stack-s15 {
    font-size: $primary-font-size + 15;
}

.font-stack-s18 {
    font-size: $primary-font-size + 18;
}
.font-stack-s22 {
    font-size: $primary-font-size + 22;
}
.font-stack-s24 {
    font-size: $primary-font-size + 24;
}
.font-stack-s34 {
    font-size: $primary-font-size + 34;
}
.font-stack-s44 {
    font-size: $primary-font-size + 44;
}


.font-stack-v1
{
    font-size:$viewable-font-size;
}

.font-stack-v1-add-point-5
{
    font-size:$viewable-font-size + .5;
}
.font-stack-v1-02
{
    font-size:$viewable-font-size + 2;
}
.font-stack-v1-05
{
    font-size:$viewable-font-size + 5;
}

.font-weight-heavy{
    font-weight: 800 !important;
}

.font-family-secondary{
    font-family: $font-stack-secondary;
}