.dialog-mask {
   background-color: rgba($color: $iron, $alpha: 0.75);
   height: 100%;
   width: 100%;
   top: 0;
   left: 0;
   overflow: hidden;
   position: fixed;
   padding: 0;
   transition: opacity 0.5s;
   z-index: 1;
}

.login-mask {
    background-color: rgba($color: $iron, $alpha: 0.4);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    padding: 0;
    transition: opacity 0.5s;
    z-index: 1;
 }

.login-background-image {
    background-image: url('../../images/Cooksongold3D_1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    padding: 0;
    transition: opacity 0.5s;
    z-index: 1;
}

.dialog-box {
    background-color: $porcelain;
    display: inline-block;
    box-sizing: content-box;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    border-radius: 1rem;
    text-align: center;

    .logo {
        height: 100px;
    }

    .title {
        text-align: center;
        vertical-align: middle;
        font-size: 2.5rem;
        font-weight: bold;
        color: $primary-color;
        padding: 2rem;
    }

    p {
        //color: $grey-text;
        font-weight: bold;         
    }

    input, textarea {
        width: calc(100% - 5rem);
        margin: 2rem;
    }

    .left-cont {
        display: inline-block;
        width: 50%;          
        text-align: right;  
        padding-right: 2rem;
        font-weight: bold;
    }
    .right-cont {
        width: 50%;
        display: inline-block;
        text-align: left;
        margin-bottom: 3rem;
    }
}
