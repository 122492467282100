.fade {
  @include transition($transition-fade);

  &:not(.show) {
    opacity: 0;
  }
}

.collapse {
  &:not(.show) {
    display: none !important;
  }
}

.collapsing {
  display: none;
  position: relative;
  height: 0;
  overflow: hidden;
  //@include transition($transition-collapse);
}
