/*! Developed by by Dilantha Perera */

.portal-container
{
    //height: auto;
    width: 100%;
    font-family: $font-stack-secondary;

    @include respond-to(small) {

        height: 100vh;
    }

    .portal-contents
    {
        color: $primary-color;
        height: 100%;

        h1 {
            font-size: 4vh;
            font-weight: 900;
    
            @include respond-to(medium) {
                font-size: 6vw;
                letter-spacing: -5px;
            }
            @include respond-to(large) {
                font-size: 90px;
            }
        }
    
        h2 {
            font-size: 3vh;
    
            @include respond-to(medium) {
                font-size: 3vw;
            }
        }
    
        h3 {
            font-size: 4vh;
            font-weight: 900;
    
            @include respond-to(medium) {
                font-size: 6vw;
                letter-spacing: -5px;
            }
            @include respond-to(large) {
                font-size: 90px;
            }
        }
    
        h4 {
            font-size: 2vh;
    
            @include respond-to(medium) {
                font-size: 2vw;
                letter-spacing: -1px;
            }
            
            @include respond-to(large) {
                font-size: 34px;
            }
        }
    
        p {
            font-size: 1.3vh;
    
            @include respond-to(medium) {
                font-size: 1.3vw;
            }
        }

        .drag-point
        {
            background: #F0F5F8;
            border: 1px dasehd #ACCAD4;
            
            label
            {
                
                font-size: 1.3vh;
                
                @include respond-to(medium) {
                    
                    font-size: 1.4vw;
                }
            }
            
        }
    }
}

.material-listing
{

    img
    {
        width: 60px;

        @include respond-to(medium) {
            width: 5vw;
            max-width: 100px;
        }
    }
    a
    {
        color:#18559e;
        text-align: left;
        display: block;

        @include respond-to(medium) {

            
            text-align: center;
        }
        
        &:hover 
        {
            text-decoration: none;

            img
            {
                transform: scale(1.1);
                transition: .2s linear all;
                border: 2px solid salmon;
            }
        }
    span
    {
        display: inline;

        @include respond-to(medium) {
            display: block;
        }
    }

    }

    
}

