

//material box-shadow
@mixin box-shadow-lvl-1($shadow-color:$black){
  box-shadow: 0 1px 3px rgba($shadow-color,0.16), 0 1px 3px rgba($shadow-color,0.23);
}
@mixin box-shadow-lvl-2($shadow-color:$black){
  box-shadow: 0 3px 6px rgba($shadow-color,0.16), 0 3px 6px rgba($shadow-color,0.23);
}
@mixin box-shadow-lvl-3($shadow-color:$black){
  box-shadow: 0 10px 20px rgba($shadow-color,0.19), 0 6px 6px rgba($shadow-color,0.23);
}
@mixin box-shadow-lvl-4($shadow-color:$black){
  box-shadow: 0 14px 28px rgba($shadow-color,0.25), 0 10px 10px rgba($shadow-color,0.22);
}
@mixin box-shadow-lvl-5($shadow-color:$black){
  box-shadow: 0 19px 38px rgba($shadow-color,0.30), 0 15px 12px rgba($shadow-color,0.22);
}

@mixin box-shadow-inset($shadow-color:$black){
  box-shadow: inset 0 0 4px rgba($color: $shadow-color, $alpha: 0.8),inset 0 0 10px rgba($color: $shadow-color, $alpha: 0.2);
}


@mixin mat-transition{
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

@mixin flex-box($justify : flex-start, $align : normal, $direction : row, $wrap : nowrap){
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
  flex-wrap: $wrap;
}