
@import 'fontawesome/fontawesome.scss';
@import 'fontawesome/brands.scss';
@import 'fontawesome/solid.scss';
@import 'fontawesome/regular.scss';


.fa-ellipsis-v
{
    &::before
    {
        content:fa-content($fa-var-ellipsis-v);
    }
}

.fa-facebook
{

    &::before
    {
        content:fa-content($fa-var-facebook);
    }
}

.fa-facebook-f
{

    &::before
    {
        content:fa-content($fa-var-facebook-f);
    }
}

.fa-twitter
{

    &::before
    {
        content:fa-content($fa-var-twitter);
    }
}

.fa-instagram
{
    &::before
    {
        content:fa-content($fa-var-instagram);
    }
}
.fa-linkedin
{

    &::before
    {
        content:fa-content($fa-var-linkedin-in);
    }
}

.fa-search
{
    &::before
    {
        content:fa-content($fa-var-search);
    }
}



.fa-shopping-cart
{
    &::before
    {
        content:fa-content($fa-var-shopping-cart);
    }
}

.fa-heart
{
    &::before
    {
        content:fa-content($fa-var-heart);
    }
}

.fa-prev
{
    &::before
    {
        content:fa-content($fa-var-chevron-right);
    }
}

.fa-next
{
    &::before
    {
        content:fa-content($fa-var-chevron-left);
    }
}

.fa-wifi
{
    &::before
    {
        content:fa-content($fa-var-wifi);
    }
}
.fa-parking
{
    &::before
    {
        content:fa-content($fa-var-parking);
    }
}

.fa-info
{
    &::before
    {
        content:fa-content($fa-var-info);
    }
}
.fa-email
{
    &::before
    {
        content:fa-content($fa-var-envelope);
    }
}

.fa-phone
{
    &::before
    {
        content:fa-content($fa-var-phone-square-alt);
    }
}
.fa-user-circle
{
    &::before
    {
        content:fa-content($fa-var-user-circle);
    }
}
.fa-site-map
{
    &::before
    {
        content:fa-content($fa-var-sitemap);
    }
}
.fa-microchip
{
    &::before
    {
        content:fa-content($fa-var-microchip);
    }
}
.fa-trophy
{
    &::before
    {
        content:fa-content($fa-var-trophy);
    }
}
.fa-chevron-up
{
    &::before
    {
        content:fa-content($fa-var-chevron-up);
    }
}
.fa-acquisitions-incorporated
{
    &::before
    {
        content:fa-content($fa-var-acquisitions-incorporated);
    }
}
.fa-compass
{
    &::before
    {
        content:fa-content($fa-var-compass);
    }
}
.fa-graduation-cap
{
    &::before
    {
        content:fa-content($fa-var-graduation-cap);
    }
}
.fa-window-restore
{
    &::before
    {
        content:fa-content($fa-var-window-restore);
    }
}
.fa-mobile-alt
{
    &::before
    {
        content:fa-content($fa-var-mobile);
    }
}
.fa-code
{
    &::before
    {
        content:fa-content($fa-var-code);
    }
}
.fa-mixcloud
{
    &::before
    {
        content:fa-content($fa-var-mixcloud);
    }
}
.fa-comments-dots
{
    &::before
    {
        content:fa-content($fa-var-comment-dots);
    }
}
.fa-user
{
    &::before
    {
        content:fa-content($fa-var-user);
    }
}
.fa-unity
{
    &::before
    {
        content:fa-content($fa-var-unity);
    }
}

.fa-lightbulb
{
    &::before
    {
        content:fa-content($fa-var-lightbulb);
    }
}

.fa-cube
{
    &::before
    {
        content:fa-content($fa-var-cube);
    }
}

.fa-fan
{
    &::before
    {
        content:fa-content($fa-var-fan);
    }
}

.fa-book
{
    &::before
    {
        content:fa-content($fa-var-book);
    }
}

.fa-atlas
{
    &::before
    {
        content:fa-content($fa-var-atlas);
    }
}

.fa-list
{
    &::before
    {
        content:fa-content($fa-var-align-justify);
    }
}




.hexagon {
    position: relative;
    width: 24px; 
    height: 14px;
    margin: 7px 0;

    &::before,
    &::after {
       content: "";
       position: absolute;
       width: 0;
       border-left: 12px solid transparent;
       border-right: 12px solid transparent;
     }
     
     &::before {
       bottom: 100%;
   
     }
     
     &::after {
       top: 100%;
       width: 0;
     }
     &:hover
     {
         zoom: 1.2;
         transition: .5s linear all;
         cursor: pointer;
     }
  }
  
