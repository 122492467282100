
.slider-navigation
{
  width: 450px;
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  @media screen and (max-width: map-get($breakpoints, 'small')) {
    transform: scale(0.6);
  }

  @include respond-to(medium) {
    width: 35%;
    height: 100%;

}


}

.canvas-wrapper {
  width: 90%;
  height: 530px;
  position: relative;
  margin: 0 auto;

  @include respond-to(medium) {
    height: 350px;

  }
  @include respond-to(large) {
    height: 550px;
    width: 440px;

  }
}
.placement-default
{
  top: 61%;
  right: 0%;
  transition: .5s linear all;

  
  @include respond-to(medium) {
    top: 69%;
    right: 0%;

  }
  @include respond-to(large) {
    top: 58%;
    right: 0%;

  }
}


.placement-two
{
  top: 44%;
  right: 56%;
  transition: .5s linear all;


  @include respond-to(medium) {
    top: 72%;

  }
  @include respond-to(large) {
    top: 57%;

  }

}
.placement-three
{
  top: 30px;
  left: 11px;
  transition: .5s linear all;


}
.placement-featured
{
  top: 14%;
  left: 61%;
  transition: .5s linear all;

  @include respond-to(medium) {
    top: 14%;

  }
  @include respond-to(large) {
    top: 14%;

  }

  &.selected
  {

    animation: highlight-scaling 1s ease-out forwards;
    
    @keyframes highlight-scaling {

      to {
        transform: scale(1.2) translate(12px, -7px);  
      }     
      
    }
  }
}


.circle-navigator {

    z-index: 100;

  &.home
  {
    width: 130px;
    max-width: 180px;
    position: absolute;
    
    text-align: center;  

    @include respond-to(medium) {
      width: 12vw;
      max-width: 150px;

  }
    
    .circle-icon
    {
      transform: scale(0.3) translate(99px, 98px);
    }
    .labeling
    {

      color: #1D5A71;
      h1
      {
        font-size: calc(.2vw + .2vh + 2vmin);
        margin: 0;

        @include respond-to(medium) {

          
          font-size: calc(.2vw + .2vh + 2vmin);
    
      }
      }
      p
      {
        font-size: calc(.2vw + .2vh + 1vmin);
      }
    }

    
  .circle
  {

    &.inner {

      transform: scale(1) translate(68px, 69px);
      stroke: #3594b7;
      fill:#1D5A71;
      stroke-width: 8;
      stroke-dasharray: 534;

    }   
  
    &.outer {
      transform: scale(1.3) translate(52px, 53px);
      stroke: #3D9DC9;
      fill: #e6eef0;
      stroke-width: 2px;
      stroke-dasharray: 5;

    }

  }

  &:hover 
  {
    cursor: pointer;
    text-decoration: none;

    .circle
    {
        stroke-dashoffset: 1000;
        animation: stroke 2s ease-out forwards;
    }
    
  }

  
  }
   
  &.create
  {
    width: 130px;
    max-width: 180px;
    position: absolute;
    
    text-align: center;  

    @include respond-to(medium) {
      width: 12vw;
      max-width: 150px;

  }
    
    .circle-icon
    {
      transform: scale(0.3) translate(99px, 98px);
    }
    .labeling
    {

      color: #3D9DC9;
      h1
      {
        font-size: calc(.2vw + .2vh + 2vmin);
        margin: 0;

        @include respond-to(medium) {

          
          font-size: calc(.2vw + .2vh + 2vmin);
    
      }
      }
      p
      {
        font-size: calc(.2vw + .2vh + 1vmin);
      }
    }

    
  .circle
  {

    &.inner {

      transform: scale(1) translate(68px, 69px);
      stroke: #60BCE6;
      fill:#3D9DC9;
      stroke-width: 8;
      stroke-dasharray: 534;

    }   
  
    &.outer {
      transform: scale(1.3) translate(52px, 53px);
      stroke: #3D9DC9;
      fill: #e5eff1;
      stroke-width: 2px;
      stroke-dasharray: 5;

    }

  }

  &:hover 
  {
    cursor: pointer;
    text-decoration: none;

    .circle
    {
        stroke-dashoffset: 1000;
        animation: stroke 2s ease-out forwards;
    }
    
  }

  
  }
 
  &.upload
  {
    width: 130px;
    max-width: 180px;
    position: absolute;
    
    text-align: center;  

    @include respond-to(medium) {
      width: 12vw;
      max-width: 150px;
      
  }

    .circle-icon
    {
      transform: scale(0.28) translate(86%, 78%);
    }
    .labeling
    {

      color: #f99d02;
      h1
      {
        font-size: calc(.2vw + .2vh + 2vmin);
        margin: 0;
      }
      p
      {
        font-size: calc(.2vw + .2vh + 1vmin);
      }
    }
    .circle
  {

    transition: stroke-dashoffset 1s;
    -webkit-animation-play-state: running;

    &.inner {

      transform: scale(1) translate(68px, 69px);
      stroke: #f99d02;
      fill: #fbba00;
      stroke-width: 8;
      stroke-dasharray: 534;

    }   
  
    &.outer {
      transform: scale(1.3) translate(52px, 53px);
      stroke: #fb8600;
      fill: #e5eff1;
      stroke-width: 2px;
      stroke-dasharray: 5;

    }

    
  }
 
      
  &:hover 
  {
    cursor: pointer;
    text-decoration: none;

    .circle
    {
        stroke-dashoffset: 1000;
        animation: stroke 2s ease-out forwards;
    }
    
  }

  }
 
  &.print
  {
    width: 130px;
    max-width: 180px;
    position: absolute;
    
    text-align: center;  

    @include respond-to(medium) {
      width: 12vw;
      max-width: 150px;
      
  }
    
    .circle-icon
    {
      transform: scale(0.13) translate(244%, 208%);
    }
    .labeling
    {

      color: #134179;
      h1
      {
        font-size: calc(.2vw + .2vh + 2vmin);
        margin: 0;
      }
      p
      {
        font-size: calc(.2vw + .2vh + 1vmin);
      }
    }

  
  .circle
  {

    transition: stroke-dashoffset 1s;
    -webkit-animation-play-state: running;

    &.inner {

      transform: scale(1) translate(68px, 69px);
      stroke: #3A76D1;
      fill: #134179;
      stroke-width: 8;
      stroke-dasharray: 534;

    }   
  
    &.outer {
      transform: scale(1.3) translate(52px, 53px);
      stroke: #3A76D1;
      fill: #e4ecef;
      stroke-width: 2px;
      stroke-dasharray: 5;

    }

    
  }
  
      
  &:hover 
  {
    cursor: pointer;
    text-decoration: none;

    .circle
    {
        stroke-dashoffset: 1000;
        animation: stroke 2s ease-out forwards;
    }
    
  }

  }

/* circle-navigator-create end ------------------------------------------- */
}

  


.the-orbit-canvas
{
  width: 100%;
  height: 100%;
  position: relative;

  svg
  {

    .the-orbit
    {
      &.curve-one
      {
        transform: scale(0.27) translate(-170%, -137%);
        overflow: hidden;
  
      }
      &.curve-two
      {
        transform: scale(0.26) translate(80%, 129%);

  
      }
      &.curve-three
      {
        transform: scale(0.26) translate(145%, 60%);

  
      }
    }
}
  



&.circle-navigator-on-move
{
    
  svg
  {
    animation : 2s infinite orbitpathrotate;
  }
  
}


}







/* Keyframes */

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}


.on-orbit
{
  animation: orbit 2s ease-out forwards;
}

@keyframes orbit {
  to {
    top: 81px;
    left: 310px
  }
}

@keyframes fadeIn {
  to {

    opacity: 1;
  }
}





@keyframes orbitpathrotate {
  50% {

    transform: rotate(100deg);
  }

}



$size: 150px;

.container-cube {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0 auto;
  width: 50%;
}

@keyframes rotate {
  0% {
    transform: rotateX(-37.5deg) rotateY(45deg);
  }
  50% {
    transform: rotateX(-37.5deg) rotateY(405deg);
  }
  100% {
    transform: rotateX(-37.5deg) rotateY(405deg);
  }
}

.inline-cube {
    $size: 15px;
}

.cube, .cube * {
  position: absolute;
  width: $size + 0;
  height: $size + 0;
}

.sides {
  animation: rotate 3s ease infinite;
  animation-delay: .8s;
  transform-style: preserve-3d;
  transform: rotateX(-37.5deg) rotateY(45deg);
}

.cube .sides * {
  box-sizing: border-box;
  background-color: rgba(242, 119, 119, .0);
  border: $size / 20 solid $chathams-blue;
}

@mixin side($name, $rotate, $i) {
  .#{$name} {
    animation: #{$name}-animation 3s ease infinite;
    animation-delay: $i * 100ms;
    transform: #{$rotate} translateZ($size);
    animation-fill-mode: forwards;
    transform-origin: 50% 50%;
  }

  
  @keyframes #{$name}-animation {
    0% { opacity: 1; transform: #{$rotate} translateZ($size)}  
    20% { opacity: 1; transform: #{$rotate} translateZ($size / 2)}  
    70% {  opacity: 1; transform: #{$rotate} translateZ($size / 2) }
    90% {  opacity: 1; transform: #{$rotate} translateZ($size) }
    100% {  opacity: 1; transform: #{$rotate} translateZ($size) }
  }
}

.cube .sides {
  @include side("top", rotateX(90deg), 0);
  @include side("bottom", rotateX(-90deg), 0);
  @include side("front", rotateY(0deg), 1);
  @include side("back", rotateY(-180deg), 1);
  @include side("left", rotateY(-90deg), 1);
  @include side("right", rotateY(90deg), 1);
}

.text {
  margin-top: $size * 3;
  color: $flamenco;
  font-size: 3rem;
  width: 100%;
  font-weight: 600;
  text-align: center;
}