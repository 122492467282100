/*! Developed by by Hemantha Wijesinghe */


.gal3d-body {
    font-family: $font-stack-secondary;
    /*background: $primary-bg url(../images/background-objekt.jpg) no-repeat 150% 20% /70%;*/
    //background-color: $pure-white;

    * {
        outline: none !important;
        touch-action: manipulation; // disable double tap zoom on IOs
    }
}

.cng3d-gal-navbar {
    background-image: linear-gradient(to left, $powder-blue, $aqua-island) !important;
}

/* --- Gallery ------------------------------------------------------------------------------- */
.gallery-section {
    display: flex;
    flex-direction: column-reverse;
    height: 100vh;
    position: relative;

    @include respond-to(medium) {
        flex-direction: row;
    }

}

.supported_levels {

    line-height:normal;
    display: inline-block;
    vertical-align:bottom;

    .type-text {
        font-size: $primary-font-size + 5;
        color: $chathams-blue;
    }

    .col{
        width: 100px;
    }

  .circle-disable
  {

    transition: stroke-dashoffset 1s;
    -webkit-animation-play-state: running;

    &.inner {

      transform: scale(1) translate(68px, 69px);
      stroke: $aqua-island !important;
      fill: $powder-blue !important;
      stroke-width: 8;
      stroke-dasharray: 534;

    }   
  
    &.outer {
      transform: scale(1.3) translate(52px, 53px);
      stroke: $iron !important;
      fill: $pure-white !important;
      stroke-width: 2px;
      stroke-dasharray: 5;

    }

    
  }

}

/* --- Gallery End ------------------------------------------------------------------------------- */



/* --- Detail page Start ------------------------------------------------------------------------------- */
.detail-contents {

    color: $primary-color;

    .detailed-gallery-area {
        height: 70.5vh;
        overflow: auto;

        /*@include respond-to(small) {
            height: 50vh;
            overflow: auto;
        }*/
    }

    .detailed-stock-gallery-area {
        height: auto;
        overflow: auto;
    }

    h1 {
        font-size: 4vh;
        font-weight: 900;

        @include respond-to(medium) {
            font-size: 6vw;
            letter-spacing: -5px;
        }
        @include respond-to(large) {
            font-size: 90px;
        }
    }

    h2 {
        font-size: 3vh;

        @include respond-to(medium) {
            font-size: 3vw;
        }
    }

    h3 {
        font-size: 4vh;
        font-weight: 900;

        @include respond-to(medium) {
            font-size: 6vw;
            letter-spacing: -5px;
        }
        @include respond-to(large) {
            font-size: 90px;
        }
    }

    h4 {
        font-size: 2vh;

        @include respond-to(medium) {
            font-size: 2vw;
            letter-spacing: -1px;
        }
        
        @include respond-to(large) {
            font-size: 34px;
        }
    }

    p {
        font-size: 1.3vh;

        @include respond-to(medium) {
            font-size: 1.3vw;
        }
    }

    .card {

        border-radius: 5px;
        &:hover {
            box-shadow: $flamenco 0px 0px 0px 2px inset;

            .card-title {
                color: $flamenco;
            }
        } 
    }

    .card-title {
        font-size: $primary-font-size + 2;        
        color: $chathams-blue;
    }

    .card-text {
        font-size: $primary-font-size - 1;
        color: $chathams-blue;
    }

    .card-reported {
        font-size: $primary-font-size - 1;
        color: $banana-green;
    }

    .card-action {
        font-size: $primary-font-size - 1;
        color: $banana-green;
        text-align: right;
    }   

    .card-edit-link {
        font-size: $primary-font-size - 1;
        color: $banana-green;
    }

    .card-remove {
        font-size: $primary-font-size + 2;
        background-color: $powder-gray;
        border-radius: 50%;
        color: $meroon-red;
        text-align: center;
        text-decoration: none;
        display: inline-block;        
        height: 25px;
        width: 25px;
    }

    .export_button {
        border:  none;
        color: $chathams-blue;
        height: 5rem;
        font-size: 1.5rem;
    }

    .search_container{
        height: 5rem
    }
    .search_controle{
        border:  none;
        border-bottom: 1px solid;
        border-radius: 0px;
        color: $chathams-blue;
        height: 5rem;
        font-size: 1.5rem;
    }

    .sort-view{
        font-size: $primary-font-size + 2;        
        color: $chathams-blue;
        text-decoration: none;
        white-space: nowrap;
    }

    .circle-icon
    {
      transform: scale(0.25) translate(76%, 73%);
    }

    .move-upload {
        width: 50px;
        height: 50px;
        padding: 6px 0px;
        border-radius: 40px;
        text-align: center;
        font-size: 24px;
        line-height: 1.42857;
        background-color:$powder-blue;
        color: $chathams-blue;
    }
    
    .move-upload.btn-xl {
        width: 50px;
        height: 50px;
        padding: 10px 16px;
        border-radius: 40px;
        font-size: 36px;
        line-height: 1.33;
        color: $chathams-blue;
    }

    .move-icon{
        font-size: $move-font-size-btn;
    }

    .upload-container{
       background-color:#f3f7f9;        
       color: $chathams-blue;
    }

}


/* --- Gallery font colors ---------------------------------------------------------------------------- */

.text-dark-blue
{
    color: $chathams-blue;
}

 
.dropdown-white {
    background: $pure-white;
    color: $chathams-blue;
    border: none;
    padding: none;
    margin: none;
}

.costing
{
    white-space: nowrap;  
}

.detailed-gallery-area img{
    max-width: 100%; 
    max-height: 100%;
}

/* --- Detail page END  ------------------------------------------------------------------------------- */

.general-pagination
{
    a
    {
        border-style: dashed;
        color: $chathams-blue;
        border-color: $chathams-blue;
        border-width: 2px;
        width: 60px;
        height: 60px;
    }
}

.test{
    display: flex;
    align-items: center;
    justify-content: center;
    height:200px !important;
}

