/* Developed by by Dilantha Perera */

.enable-scroll-styling {
    scrollbar-color:#0f5584 #fff;
    scrollbar-width: thin;
  }
  
.enable-scroll-styling::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.enable-scroll-styling::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #F5F5F5;
}

.enable-scroll-styling::-webkit-scrollbar-thumb {
    border-radius: 10px;
    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #0f5584;
}


$pure-white :#ffffff;
$black :#000000;
$iron : #d6dfe0;
$golden-yellow: #FDD106;
$chathams-blue :#0E4D80;
$matisse-blue :#1B68A7;
$aqua-island : #E5EDF0;
$holiday-blue: #33bfd0;
$pelorous : #3D9DC9;
$flamenco : #ff7907;
$orange-peel : #FFA200;
$theme-red : #d3411f;
$celtic : #163F21;
$mahogani : #861e07;
$elephant : #103140;
$porcelain : #F9FBFB;
$powder-blue: #ecf4f6;
$banana-green: #08814d;
$powder-gray: #e4e4e4;
$meroon-red : #8b4260;

