.portal-rendering-engine
{
    background: #c5dbe2;
    height: 100%;
    width: 100%;
    flex-direction: column;

    .model-3d-viewer
    {
        vertical-align: top;
        padding: 0;
        margin: 0vh;

        .scrub-icon {
            position: absolute;
            top: 2rem;
            right: 13%;
            }

        //border: #bbced4 solid 1px;
    }

    .buttons
    {
        justify-content: center;
        padding-bottom: 1vh;
    }

    .default-icon-arrangement
    {
        width: 72.5%;
        margin: 1vh;
        padding: 10vh;
    }
}

.detail-image 
{
    @media screen and (min-width: map-get($breakpoints, 'small')) {
        display: none;
    }
    margin: auto;
    width: 50%;

    border: $chathams-blue 1px solid;
}