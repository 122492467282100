@import '../colors';

.loading-overlay {
   background-color: rgba($color: $iron, $alpha: 0.75);
   height: 0;
   width: 0;
   opacity: 0;
   top: 0;
   left: 0;
   overflow: hidden;
   position: fixed;
   padding: 0;
   transition: opacity 0.5s;
   z-index: 999;

   &.visible {
      opacity: 1;
      height: 100%;
      width: 100%;
   }

   .loading-box {
      background-color: $porcelain;
      display: inline-block;
      box-sizing: content-box;
      position: fixed;
      top: 50%;
      left: 50%;
      opacity: 0;
      transform: translate(-50%, -50%);
      padding: 10px;
      border-radius: 1rem;
      text-align: center;

      &.visible {
        width: 30rem;
        height: 20rem;
        opacity: 1;
      }
   }

   p {
        text-align: center;
        vertical-align: middle;
        font-size: 2.5rem;
        font-weight: bold;
        color: $primary-color;
        padding: 2rem;
   }
}

.loading-inline {
   height: 20rem;
   margin-bottom: 5rem;
   padding-bottom: 5rem;
   &:not(.completed) {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;      
   }
}

.spinner {
   color: $chathams-blue;
   font-size: 0;
   text-indent: -9999em;
   overflow: hidden;
   width: 1em;
   height: 1em;
   border-radius: 50%;
   margin: 0 auto;
   margin-top: 5rem;
   position: relative;
   -webkit-transform: translateZ(0);
   -ms-transform: translateZ(0);
   transform: translateZ(0);
   -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
   animation: load6 1.7s infinite ease, round 1.7s infinite ease;
   &.visible {
      font-size: 4rem;
   }
   &.inline {
      font-size: 4rem;
      margin: 5rem;
   }
}

@-webkit-keyframes load6 {
   0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
         0 -0.83em 0 -0.477em;
   }
   5%,
   95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
         0 -0.83em 0 -0.477em;
   }
   10%,
   59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
         -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
   }
   20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
         -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
   }
   38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em,
         -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
   }
   100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
         0 -0.83em 0 -0.477em;
   }
}
@keyframes load6 {
   0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
         0 -0.83em 0 -0.477em;
   }
   5%,
   95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
         0 -0.83em 0 -0.477em;
   }
   10%,
   59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
         -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
   }
   20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
         -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
   }
   38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em,
         -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
   }
   100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
         0 -0.83em 0 -0.477em;
   }
}
@-webkit-keyframes round {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
   }
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}
@keyframes round {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
   }
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}